import React from 'react';
import PropTypes from 'prop-types';

import BlockTitle from '../../layout/BlockTitle';
import './styles.scss';
import { graphql, useStaticQuery, navigateTo } from 'gatsby';
import ButtonComponent from '../../layout/Button';

const SomeComponent = props => {
    const data = useStaticQuery(query);

    return (
        <div role="component" id="some-component" style={{ backgroundImage: `url(${data.image.childImageSharp.fluid.src})`}}>
            <div className="content">
                <div className="blockTitle">
                    <BlockTitle title="Lorem ipsum" />
                </div>
                <div className="blockContent">
                    <h1>Technologie przyjazne środowisku</h1>
                    <ButtonComponent
						title="Zobacz więcej"
						fontColor="#fff"
						onClick={() => navigateTo("/o-firmie")}
					/>
                </div>
            </div>
        </div>
    )
}

export default SomeComponent;

const query = graphql`
{
    image: file(relativePath: {eq: "meadow.jpg"}) {
    	childImageSharp{
			fluid(maxWidth:2000, maxHeight: 1000){
				...GatsbyImageSharpFluid
			}
    	}
  	}
}
`