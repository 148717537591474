import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const BlockTitleComponent = props => {

    const { title, color } = props;

    return (
        <div role="component" id="block-title-component">
            <h1 className="title" style={{ color: color }}>{title}</h1>
            <div className="border"></div>
        </div>
    )
}

BlockTitleComponent.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string
}

BlockTitleComponent.defaultProps = {
    color: '#fff'
}

export default BlockTitleComponent;