import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Container from '../../layout/Container';

import './styles.scss';

const HomePageContent = props => {
    const data = useStaticQuery(query);

    return (
        <div role="component" id="home-page-content-component"> 
            <Container>
                <div dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }}>

                </div>
            </Container>
        </div>
    )
}

export default HomePageContent;

export const query = graphql`
{
    content: file(relativePath: {eq: "homePage/content.md"}) {
	    childMarkdownRemark{
            html
        }
    }
}
`;