import React from 'react';
import PropTypes from 'prop-types';

import BlockTitle from '../../layout/BlockTitle';

import './styles.scss';
import Container from '../../layout/Container';
import Button from '../../layout/Button';
import Triangle from '../../layout/Triangle';
import ContactForm from '../../layout/ConctactForm';

import FontAwesome from 'react-fontawesome';

export default class ContactSectionComponent extends React.Component {

    state = {
        modalOpen: false
    }

    render() {
        const { modalOpen } = this.state;

        return (
            <>
                <div role="component" id="contact-section-component">
                    <div className="block-title">
                        <BlockTitle title="Kontakt z nami" color="black" />
                    </div>
                    <div className="content">
                        <Container>
                            <p>Lorem Ipsum jest tekstem stosowanym jako przykładowy wypełniacz w przemyśle poligraficznym. Został po raz pierwszy użyty w XV w. przez nieznanego drukarza do wypełnienia tekstem próbnej książki. Pięć wieków później zaczął być używany przemyśle elektronicznym, pozostając praktycznie niezmienionym. Spopularyzował się w latach 60. XX w. wraz z publikacją arkuszy Letrasetu, zawierających fragmenty Lorem Ipsum, a ostatnio z zawierającym różne wersje Lorem Ipsum oprogramowaniem przeznaczonym do realizacji druków na komputerach osobistych, jak Aldus PageMaker</p>
        
                            <Button 
                                title="Formularz kontaktowy" 
                                onClick={() => this.setState({ modalOpen: true })}
                            />
                        </Container>
                    </div>
                    <div class={`modal ${modalOpen ? 'is-active' : ''}`}>
                        <div class="modal-background" onClick={() => this.setState({ modalOpen: false })}></div>
                        <div class="modal-content">
                            <div className="box">
                                <ContactForm />
                            </div>
                        </div>
                        <button class="modal-close is-large" aria-label="close"></button>
                    </div>
                </div>
            </>
        )
    }
}
