import React from 'react';
import PropTypes from 'prop-types';

import Container from '../../layout/Container';
import Columns from '../../layout/Columns';
import Column from '../../layout/Column';
import BlockTitle from '../../layout/BlockTitle';
import Button from '../../layout/Button';
import offers from '../../../data/homePage/offers';
import './styles.scss';
import { navigateTo, useStaticQuery, graphql } from 'gatsby';

const Offers = props => {
    const data = useStaticQuery(query)

    return <OffersComponent data={data.images.edges} />
}

class OffersComponent extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired
    }
    static defaultProps = {}

    offerInterval = null;
    time = 5000;
    state = {
        activeOfferIndex: 0,
        activeImageUrl: null
    } 

    componentDidMount = () => {
        const { data } = this.props;

        this.setState({
            ...this.state,
            activeImageUrl: data.filter(element => element.node.name == offers[0].image)[0].node.childImageSharp.fluid.src
        });
        this.setInterval();
    }

    setInterval = () => {
        const { data } = this.props;

        this.offerInterval = setInterval(() => {
            this.setState(prevState => {
                const activeIndex = this.state.activeOfferIndex === offers.length - 1 ? 0 : this.state.activeOfferIndex + 1;
                return {
                    ...this.state,
                    activeOfferIndex: activeIndex,
                    activeImageUrl: data.filter(element => element.node.name == offers[activeIndex].image)[0].node.childImageSharp.fluid.src
                }
            })
        }, this.time)
    }

    switchOffer = index => {
        const { data } = this.props;

        clearInterval(this.offerInterval);
        this.setState(prevState => {
            return {
                ...this.state,
                activeOfferIndex: index,
                activeImageUrl: data.filter(element => element.node.name == offers[index].image)[0].node.childImageSharp.fluid.src
            }
        }, () => this.setInterval())
    }

    render() {
        const { activeOfferIndex, activeImageUrl } = this.state;
        return (
            <div role="component" id="offer-component" style={{ backgroundImage: `url(${activeImageUrl})`}}>
                <div className="offer-playground">
                    <div className="block-title">
                        <BlockTitle title="Oferta" />
                    </div>
                    <div className="block-content">
                        <Container>
                            <Columns>
                                <Column number={4}>
                                    <ul className="offer-links">
                                        {offers.map((offer, index) => (
                                            <li className="offer-link">
                                                <a className={index === activeOfferIndex ? 'is-active' : '' } onClick={() => this.switchOffer(index)}>{offer.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </Column>
                                <Column>
                                    <div className="offers-content">
                                        {offers.map((offer, index) => (
                                            <div  
                                                className={`${index === activeOfferIndex ? 'offer visible animated fadeIn' : 'offer'}`}
                                            >
                                                <div className="offer-content">
                                                    <h1 className="offer-title">{offer.title}</h1>
                                                    <p className="offer-text">{offer.content}</p>
                                                    <Button 
                                                        onClick={() => navigateTo(offer.slug)}
                                                        title="Zobacz więcej"
                                                        fontColor="#fff"
                                                    />
                                                </div>
                                            </div>     
                                        ))}
                                    </div>
                                </Column>
                            </Columns>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default Offers;

const query = graphql`
{
    images: allFile(filter: { extension: { eq: "jpg" }, relativeDirectory: { eq: "offers" }}){
        edges {
        	node {
                name
				childImageSharp {
					fluid (
						maxWidth: 1900,
						maxHeight: 1080					)
					{
						...GatsbyImageSharpFluid
					}
            	}
        	}
        }
  	}
}
`;

