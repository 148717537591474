import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TriangleComponent = props => {

    const { color } = props;
    
    return (
        <div role="component" id="triangle-component" style={{ borderColor: `transparent transparent ${color}` }}>

        </div>
    )
}

TriangleComponent.propTypes = {
    color: PropTypes.string
}
TriangleComponent.defaultProps = {
    color: '#fff'
}

export default TriangleComponent;