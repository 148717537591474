import React from "react"

import Layout from "../components/layout/Layout"
import SEO from '../components/layout/Seo';
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer';
import HomeParallax from '../components/homePage/HomeParallax';
import HomeSlider from '../components/homePage/HomeSlider';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import HomePageContent from '../components/homePage/Content';
import Playground from "../components/layout/Playground";
import Map from "../components/layout/Map";
import { Animated } from "react-animated-css";
import Offers from '../components/homePage/Offers';
import Contact from '../components/homePage/Contact';
import SomeComponent from '../components/homePage/SomeComponent';
import Preloader from '../components/layout/Preloader';
import { useStaticQuery } from "gatsby";

export default class IndexContainer extends React.Component {
	state = {
		loaded: false
	}
	interval = null;

	componentDidMount = () => {
		this.interval = setInterval(() => {
			this.setState({ loaded: true })
		}, 250)
	}

	componentWillUnmount = () => {
		clearInterval(this.interval)
	}

	render() {
		const { loaded } = this.state;
		const { location } = this.props ;

		return (
			<Layout>
				{Boolean(!loaded) && <Preloader /> }
				<SEO pageTitle="Home" />
				<Header location={location} />
				<Playground>
					<HomeSlider />
					<Breadcrumbs 
						way={[
							{
								title: 'Strona główna',
								url: '/',
								active: true
							}
						]}
					/>
					<Animated>
						<HomePageContent />
						<Offers />
						<Contact />
						<SomeComponent />
						<Map />	
					</Animated>
				</Playground>
				<Footer />
			</Layout>
		)
	}
}

