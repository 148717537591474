import React from 'react'
import { navigateTo, StaticQuery, graphql } from 'gatsby';

import Button from '../../layout/Button';


import './styles.scss';

export default class HomeSlider extends React.Component {

    state = {
        scroll: 0,
        currentIndex: 0
    }
    interval = null

    componentDidMount = () => {
        this.interval = setInterval(() => {
            this.setState(prevState => ({
                ...this.state,
                currentIndex: this.state.currentIndex >= 3 ? 0 : this.state.currentIndex + 1
            }))
        }, 5000)
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => this.setState({ scroll : window.scrollY });

    renderSlides = (data, scroll) => {
        const { currentIndex } = this.state;

        return (
            <div className="sliderBackground" style={{ 
                backgroundPositionY: `${scroll / 10}%`, 
                backgroundImage: `url(${data.images.edges[currentIndex].node.childImageSharp.fluid.src})`
            }}>
                <div className="sliderItem animated fadeIn">
                    <p style={{ opacity: `${1 - scroll / 400 }`}} className="title">Technologie przyjazne środowisku</p>
                    <p style={{ opacity: `${1 - scroll / 400 }`}} className="subtitle">Lorem ipsum</p>
                    <div style={{ opacity: `${1 - scroll / 400 }`}}>
                        <Button
                            title="Zobacz więcej"
                            fontColor="#fff"
                            onClick={() => navigateTo("/o-firmie")}
                        />  
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { scroll } = this.state;

        return (
            <div role="component" id="home-slider-component">
                <section className="hero">
                    <div className="hero-body">
                        <StaticQuery
                           query={query}
                           render={data => this.renderSlides(data, scroll)}
                        />
                    </div>
                </section>
            </div>

        )
    }
}

const query = graphql`
{
    images : allFile(filter: { relativeDirectory: {eq: "homeSlider"}}){
        edges {
        	node {
				childImageSharp {
					fluid (
						maxWidth: 2000,
						maxHeight:700
					)
					 {
						...GatsbyImageSharpFluid
					}
            	}
        	}
        }
  	}
}

`