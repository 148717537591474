import React from 'react';
import PropTypes from 'prop-types';
import BlockTitleComponent from '../BlockTitle';

import './styles.scss';

const ContactForm = props => {
    return (
        <div role="component" id="contact-form-component">
			<BlockTitleComponent title="Formularz kontaktowy" color="black" />
           	<div className="field">
				<label className="label">Nazwa firmy</label>
				<div className="control">
					<input className="input" type="text" placeholder="Text input"/>
				</div>
			</div>
			<div className="field">
				<label className="label">Adres email</label>
				<div className="control">
					<input className="input" type="text" placeholder="Text input"/>
				</div>
			</div>
			<div className="field">
				<label className="label">Numer telefonu</label>
				<div className="control">
					<input className="input" type="text" placeholder="Text input"/>
				</div>
			</div>
			<div class="field">
				<label class="label">Temat</label>
				<div class="control">
					<div class="select">
					<select>
						<option>Select dropdown</option>
						<option>With options</option>
					</select>
					</div>
				</div>
			</div>
			<div class="field is-grouped is-grouped-right">
				<div class="control">
					<button class="button is-link">Wyślij</button>
				</div>
			</div>
		</div>
    );
}

export default ContactForm;